<template>
  <v-container fluid><mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'crm.employees'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1> {{ $t('employee.create.title') }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="employeeCreate"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <v-card>
          <v-card-title>
            {{ $t('employee.titles.personal_data') }}
          </v-card-title>
          <v-card-text>
            <v-form class="pa-4">
              <v-row>
                <v-col cols="12" md="9">
                  <v-autocomplete
                      v-model="autocomplete_watcher"
                      :items="items"
                      :loading="isLoading"
                      :search-input.sync="search"

                      clearable
                      hide-details
                      hide-selected
                      itemscope
                      :item-text="search_item_text === 0 ? 'first_name' : 'last_name'"
                      item-value="id"
                      :label="$t('employee.create.employee_search_label')"
                      solo
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          {{ $t('employee.create.employee_search_info') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <span>{{ item.first_name }} {{ item.last_name }}</span>
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.first_name }} {{ item.last_name }} / <small style="color: #b7b7b7">{{ item.position }}</small></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="mt-n1" cols="12" md="2">
                  <small>{{ $t('employee.crm.search.user.title') }}</small>
                  <v-btn-toggle v-model="search_item_text" mandatory>
                    <v-btn small>
                      {{ $t('employee.crm.search.user.first_name') }}
                    </v-btn>
                    <v-btn small>
                      {{ $t('employee.crm.search.user.last_name') }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.first_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('employee.first_name') + ' *'"
                                :placeholder="$t('employee.first_name')"
                                required
                                :error="errors.first_name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.last_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('employee.last_name') + ' *'"
                                :placeholder="$t('employee.last_name')"
                                required
                                :error="errors.last_name!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="d-flex">
                  <v-select v-model="form.gender"
                            :label="$t('employee.gender.title') + ' *'"
                            :items="gender_select"
                            outlined
                            disabled
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.birthday"
                                outlined
                                hide-details
                                disabled
                                required
                                :label="$t('employee.birthday') + ' *'"
                                :placeholder="$t('employee.birthday')"
                                :error="errors.birthday!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.position"
                                outlined
                                hide-details
                                :label="$t('employee.position') + ' *'"
                                :placeholder="$t('employee.position')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="form.role"
                            :label="$t('user.role.title') + ' *'"
                            :items="role_select"
                            item-value="name"
                            item-text="display_name"
                            outlined
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.email"
                                outlined
                                hide-details
                                type="email"
                                :label="$t('employee.email') + ' *'"
                                :placeholder="$t('employee.email')"
                                required
                                :error="errors.email!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.password"
                                outlined
                                hide-details
                                disabled
                                :label="$t('employee.password') + ' *'"
                                :placeholder="$t('employee.password')"
                                required
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <v-card class="mb-6">
          <v-card-title>{{ $t('employee.titles.settings') }}</v-card-title>
          <v-card-text>
            <v-switch
                v-model="form.email_send"
                :label="$t('employee.email_send')"
                color="primary"
            ></v-switch>
            <v-switch
                v-model="form.is_blocked"
                :label="$t('employee.is_blocked')"
                color="primary"
            ></v-switch>
          </v-card-text>
        </v-card>
        <v-card class="mb-6" style="padding-bottom: 11px;">
          <v-card-title>{{ $t('employee.titles.password') }}</v-card-title>
          <v-card-text>
            <PasswordGenerator @getPassword="getPassword"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar_error"
        top
        right
        color="red"
    >
      <template v-if="errors.uid!==undefined">
        {{ $t('employee.create.errors.user_hast') }}
      </template>
      <template v-else>
        {{ $t('employee.create.errors.required_fields') }}
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PasswordGenerator from "@/components/PasswordGenerator";
import moment from "moment";

export default {
  components: {PasswordGenerator},
  data() {
    return {
      search_item_text: 0,
      preloader: true,
      snackbar_error: false,
      isLoading: false,
      items: [],
      search: null,
      autocomplete_watcher: null,
      form: {
        identification: '',
        position: '',
        first_name: '',
        last_name: '',
        email: '',
        password: null,
        gender: 'female',
        role: 'user',
        is_admin: 0,
        is_blocked: 0,
        email_send: 0,
      },
      role_select: [],
      gender_select: [
        {
          text: this.$t('user.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('user.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('user.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('user.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      errors: {},
    }
  },
  watch: {
    autocomplete_watcher (id) {
      if (id === null) {return}
      const item = this.items.find(item => item.id === id)

      this.form.uid = item.id
      this.form.identification = item.identification
      this.form.first_name = item.first_name
      this.form.last_name = item.last_name
      this.form.gender = item.gender
      this.form.birthday = this.getBirthday(item.birthday)
      this.form.position = item.position
      this.form.email = item.email
    },
    // search (val) {
    //   this.isLoading = true
    //   this.getItems()
    // },
  },
  async created() {
    await this.$store.dispatch('rolesEmployeeLaratrust').then(() => {
      this.role_select = this.$store.getters.roles_employee_laratrust.data
    })

    this.getItems()
    setTimeout(() => {
      this.preloader = false
    }, 300)
  },
  methods: {
    getItems() {
      this.$store.dispatch('employees').then(() => {
        this.items = this.$store.getters.employees.data
        this.isLoading = false
      })
    },
    getPassword(password) {
      this.form.password = password
    },
    employeeCreate() {
      this.$store.dispatch('employeeCrmCreate', this.form).then(() => {
        this.$router.replace({
          name: 'crm.employees'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.snackbar_error = true
            break;
        }
      })

    },
    getBirthday(date,format='YYYY-MM-DD') {
      return moment(date, format).format('DD.MM.YYYY')
    }
  }
}
</script>
